import React from "react";
import tw, { styled, css } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import { styles as contentStyles } from "./Content";

const Section = tw.div`flex flex-col items-center gap-6`;

const Image = styled.div(({ inview }) => [
  tw`duration-1000 ease-in-out translate-x-[-75px] opacity-0`,
  inview && tw`translate-x-0 opacity-100`,
]);
const Position = tw.p`font-mazuis italic text-5xl`;
const Awards = tw.div`flex flex-row flex-wrap items-center gap-6 justify-center w-full`;
const Award = tw.div`max-w-[30%]`;
const Content = styled.div(() => [
  contentStyles,
  tw`w-full`,
  css`
    h2 {
      ${tw`mb-0 text-lg`}
    }

    & > ul > li {
      ${tw`opacity-70`}
    }
  `,
]);

const ProfileComp = ({ picture, details, position, awards, ...rest }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <Section {...rest}>
      {picture && (
        <Image ref={ref} inview={inView}>
          <GatsbyImage
            image={getImage(picture.localFile)}
            alt={picture.altText}
          />
        </Image>
      )}
      <Position>{position}</Position>
      {!!awards && (
        <Awards>
          {awards?.map(({ award }, i) => (
            <Award>
              <GatsbyImage
                image={getImage(award.localFile)}
                alt={award.altText}
              />
            </Award>
          ))}
        </Awards>
      )}
      <Content dangerouslySetInnerHTML={{ __html: details }} />
    </Section>
  );
};

export default ProfileComp;
