/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";
import { getBreadcrumbs } from "../../lib/functions";

import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import Content from "../components/PageContent";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import FrontReviews from "../components/FrontReviews";
import YourTeam from "../components/YourTeam";
import CaseResults from "../components/CaseResults";
import Awards from "../components/Awards";
import InnerTitleBlock from "../components/InnerTitleBlock";
import SideForm from "../components/SideForm";
import ProfileComp from "../components/ProfileComp";

const Profile = ({ data }) => {
  const { wpPage: page } = data;
  const { title, content, seo, pageSettings, profile } = page;

  return (
    <Layout seo={seo} inner schemaCodes={pageSettings.schemaCodes}>
      <InnerTitleBlock
        header={pageSettings?.header ? pageSettings?.header : title}
        breadcrumbs={getBreadcrumbs(seo)}
      />
      <PageWrapper inner>
        <SideWrapper profile>
          <ProfileComp {...profile} />
          <SideForm profile title={pageSettings?.formTitle} />
        </SideWrapper>
        <ContentWrapper profile>
          <Content content={content} />
        </ContentWrapper>
      </PageWrapper>
      <FrontReviews />
      <YourTeam />
      <CaseResults />
      <Awards inner />
    </Layout>
  );
};

export default Profile;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      pageSettings {
        header
        formTitle
        schemaCodes {
          singleSchema
        }
      }
      profile: attorneyProfile {
        details: profileDetails
        position: profilePosition
        picture: profilePicture {
          ...GatsbyImage_Blur
        }
        awards {
          award {
            ...GatsbyImage_Blur
          }
        }
      }
    }
  }
`;
